import React, { useEffect } from 'react';
import { navigate, Link } from 'gatsby';
import queryString from 'query-string';
import { MailIcon } from '@heroicons/react/solid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Layout from '../components/layout/layout';
import { isLoggedIn, logout } from '../utils/auth';
import { postApiRequest } from '../utils/request';
import SEO from '../components/layout/seo';
import Panel from '../components/common/panel';

const ResetPassword = () => {
  useEffect(() => {
    if (isLoggedIn()) {
      logout();
    }
  }, []);


  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().min(6, 'Password must be at least 6 characters.').required('Password is a required field.'),
      password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords do not match!').required('Password confirmation is a required field.'),
    }),
    onSubmit: async (values, actions) => {
      // console.log(values, 'these are the values');
      // console.log(actions, 'actions');

      const query = window.location.search ? queryString.parse(window.location.search) : {};
      const { email, token } = query;
      try {
        const response = await postApiRequest('/auth/reset-password', {...values, email, token });
        console.log(response, 'response');
        // TODO: Need to set a page alert and let them know!!!!
        navigate('/sign-in');
      } catch (err) {
        actions.setErrors({
          password: 'There was an error processing your request, please try again.',
        });
        // console.log(err.response, 'the error from the request');
      }
      actions.setSubmitting(false);
    },
  });

  const regularInputClasses = 'border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500';
  const errorInputClasses = 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500';

  const hasPasswordError = formik.touched.password && formik.errors.password;
  const hasPasswordConfirmationError = formik.touched.password_confirmation && formik.errors.password_confirmation;

  return (

    <Layout classes="bg-gray-50">
      <SEO title="Reset Password" />
      <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <Panel>
            <div className="py-6 px-4">
              <div>
                <Link to="/">
                  <img
                    className="mx-auto h-24 w-auto"
                    src="/fbl-logo.png"
                    alt="Fun Bowling Leagues Logo"
                  />
                </Link>
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Reset your password</h2>
                {/* <p className="mt-2 text-center text-sm text-gray-600">
                  Or{' '}
                  <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                    start your 14-day free trial
                  </a>
                </p> */}
              </div>
              <form className="mt-8 space-y-6" onSubmit={formik.handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <input
                      {...formik.getFieldProps('password')}
                      id="password"
                      name="password"
                      type="password"
                      className={`${hasPasswordError ? errorInputClasses : regularInputClasses} appearance-none rounded-none relative block w-full px-3 py-2 border rounded-t-md focus:outline-none focus:z-10 sm:text-sm`}
                      placeholder="New password"
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Confirm password
                    </label>
                    <input
                      {...formik.getFieldProps('password_confirmation')}
                      id="password_confirmation"
                      name="password_confirmation"
                      type="password"
                      className={`${hasPasswordError ? errorInputClasses : regularInputClasses} appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b-md focus:outline-none focus:z-10 sm:text-sm`}
                      placeholder="Confirm new password"
                    />
                    {hasPasswordError && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {formik.errors.password}
                      </p>
                    )}
                    {hasPasswordConfirmationError && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {formik.errors.password_confirmation}
                      </p>
                    )}
                  </div>
                </div>

                {/* <div className="flex items-center justify-between">

                  <div className="text-sm">
                    <Link to="/sign-in" className="font-medium text-indigo-600 hover:text-indigo-500">
                      Return to sign in.
                    </Link>
                  </div>
                </div> */}

                <div>
                  <button
                    type="submit"
                    disabled={formik.isSubmitting}
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <MailIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                    </span>
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </Panel>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
